import { format } from "date-fns";
import { Modal, Table, Form, Button } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import {
  cacheTimes,
  queryActions,
  requisitionActions,
} from "../../utils/reactQueryActions";
import { ApproveModalIcon, FileLargeIcon } from "../Icons";
import PaperClipIcon from "mdi-react/PaperclipIcon";
import { truncate } from "lodash";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import { last } from "lodash";
import { useCanGoBack } from "../../utils/hooks";
import { styleHelper } from "../../utils/helpers";
import moment from "moment";
import { isEmpty, uniqBy } from "lodash";

export default function ApproveAndSendModal({
  location,
  requestid,
  setShowApproveAndSendModal,
  refetchRequisition,
}) {
  const { user: authUser, backendUrl } = useAuth();
  // const queryClient = useQueryClient();
  const goBack = useCanGoBack();
  let { id: requisitionid } = useParams();
  const id = useMemo(() => {
    return requisitionid && requisitionid.includes("REQ")
      ? requisitionid
      : requestid;
  }, [requestid, requisitionid]);

  const [reciever, setReceiver] = useState();

  const getNextUserToPerformAction = (requisition) => {
    const requisitiontracks = requisition?.requisitiontracks;
    let permissions = [];
    const page = JSON.parse(requisition?.filledFormData)[0];
    const signatories = page.children
      .filter((el) => el.type === "signatoryElement")
      .map((el) => el.props.fields);
    if (signatories) {
      permissions = signatories.flat().map((el) => el.permission);

      if (permissions) {
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length;
        setReceiver(permissions[lastTrack]);

        formik.setFieldValue("department", permissions[lastTrack].department);
        formik.setFieldValue("recipient", permissions[lastTrack].staff);
        formik.setFieldValue("jobGrade", permissions[lastTrack].jobGrade);
      }
    }
  };

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.requisition?.filledFormData) {
      getNextUserToPerformAction(data.requisition);
    }

    return data;
  };
  const { data: { requisition } = { requisition: null } } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {}
  );

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { data: { departments } = { departments: null } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const requisitionAction = async () => {
    let response = await fetch(`${backendUrl}/api/requisition/action`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        requestid: requisition?.requestid,
        sentby: authUser.Staff_ID,
        receivedby: formik.values.recipient,
        status: "Approved & Sent",
        // format(new Date(), "yyyy-MM-dd"),
        regdate: moment(),
        comment: formik.values.message,
      }),
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { isFetching: isSubmitting, refetch: submit } = useQuery(
    [requisitionActions.ACTION],
    () => requisitionAction(),
    {
      enabled: false,
      onError: () => toast.error("Unable to Approve & send"),
      onSuccess: (data) => {
        toast.success("Requisition Approved & sent");
        eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
        if (refetchRequisition) {
          refetchRequisition();
        }
        if (setShowApproveAndSendModal) {
          return setShowApproveAndSendModal(false);
        }

        goBack("/requisition/request");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      department: "",
      recipient: "",
      jobGrade: "",
      message: "",
    },
    validationSchema: yup.object().shape({
      department: yup.string().required(),
      recipient: yup.string().required(),
      message: yup.string(),
    }),
    onSubmit: (values) => {
      submit();
    },
  });

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { users } = { users: null },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  const canPerformAction = () => {
    const pendingRequisition = last(requisition?.requisitiontracks);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values.department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    return {
      departments: data.departments,
      jobGrades: uniqBy(jobGrades, "jobGrade"),
      staffs: data.staffs.filter(
        (el) =>
          el.Department === formik.values.department &&
          el.jobGrade === formik.values.jobGrade
      ),
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  return (
    <Modal
      show={true}
      onHide={() => {
        if (setShowApproveAndSendModal) {
          return setShowApproveAndSendModal(false);
        }
        goBack("/requisition/request");
      }}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />

      <Modal.Body className="approve-action">
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          style={!canPerformAction() ? { pointerEvents: "none" } : {}}
        >
          <div className="text-info-h">
            <ApproveModalIcon className="mb-4" />
            <h2>Approve and Sent Requisition</h2>
            <p>are you sure you want to approve and send the Requisition ?</p>
          </div>
          <div className="border rounded">
            <h3 className="border-bottom mb-0 p-3">
              <FileLargeIcon className="me-3" />
              <span> {requisition?.title}</span>
            </h3>

            <div className="d-md-flex p-3">
              <Table borderless className="info-table mb-0 mb-md-3">
                <tbody>
                  <tr className="text-dark">
                    <td>Type:</td>
                    <td>
                      {requisition?.type} <br />
                      <span
                        className="text-light d-block mt-1"
                        title={requisition?.title}
                      >
                        {truncate(requisition?.title, { length: 30 })}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table borderless className="info-table">
                <tbody>
                  <tr>
                    <td>ID requisition :</td>
                    <td>#{id}</td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>
                      {requisition?.regdate
                        ? format(
                            new Date(requisition?.regdate),
                            "dd / MM / yyyy"
                          )
                        : "..."}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="mt-4 py-4 border-top">
            {requisition?.filledFormData ? (
              <>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Department :</Form.Label>
                  <Form.Select
                    name="department"
                    value={formik.values.department}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.department && !!formik.errors.department
                    }
                    disabled={reciever?.department}
                  >
                    <option value="">Any Department</option>
                    {data?.departments &&
                      data.departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.department}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Job Grade :</Form.Label>
                  <Form.Select
                    name="jobGrade"
                    value={formik.values.jobGrade}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.jobGrade && !!formik.errors.jobGrade
                    }
                    disabled={reciever?.jobGrade}
                  >
                    <option value="">Any Job Grade</option>
                    {staffSelectOptions?.jobGrades &&
                      staffSelectOptions.jobGrades.map((el, index) => (
                        <option key={index} value={el.jobGrade}>
                          {el.jobGrade}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.jobGrades}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Recipient :</Form.Label>
                  <Form.Select
                    name="recipient"
                    value={formik.values.recipient}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.recipient && !!formik.errors.recipient
                    }
                    disabled={isFetching || reciever?.staff}
                  >
                    <option value="">Any Staff </option>
                    {staffSelectOptions?.staffs &&
                      staffSelectOptions?.staffs.map((el, index) => (
                        <Fragment key={index}>
                          {el.Staff_ID !== authUser.Staff_ID && (
                            <option value={el.Staff_ID}>{el.Name}</option>
                          )}
                        </Fragment>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.recipient}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Department :</Form.Label>
                  <Form.Select
                    name="department"
                    value={formik.values.department}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.department && !!formik.errors.department
                    }
                  >
                    <option value="">Select Recipient's Department</option>
                    {departments &&
                      departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.department}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient :</Form.Label>
                  <Form.Select
                    name="recipient"
                    value={formik.values.recipient}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.recipient && !!formik.errors.recipient
                    }
                    disabled={isLoadingUsers}
                  >
                    <option value="">Select Recipient </option>
                    {users &&
                      users.map((el, index) => (
                        <Fragment key={index}>
                          {el.Staff_ID !== authUser.Staff_ID && (
                            <option value={el.Staff_ID}>{el.Name}</option>
                          )}
                        </Fragment>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.recipient}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Message :</Form.Label>
              <label className="border p-0 rounded pb-2 message-area d-block">
                <Form.Control
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  as="textarea"
                  className="w-100 border-0 p-3"
                  placeholder="Enter your detail description"
                  rows={4}
                />

                <Button variant="white" className="text-light mx-1">
                  <PaperClipIcon />
                </Button>
              </label>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      {canPerformAction() && (
        <Modal.Footer>
          <span />
          <div className="proceed-actions">
            <Button
              disabled={isSubmitting}
              type="button"
              variant="white"
              className="border bg-white"
              onClick={() => {
                if (setShowApproveAndSendModal) {
                  return setShowApproveAndSendModal(false);
                }
                goBack("/requisition/request");
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              onClick={() => formik.submitForm()}
              variant="primary"
            >
              {isSubmitting ? "Please wait…" : "Okay"}
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
