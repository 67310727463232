import { useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';

export function BusinessDevelopmentCRMNav(props) {
  const cookies = new Cookies();
  const [items] = useState([
    {
      name: "Customer List",
      to: "customer-list",
    },
    {
      name: "Job List",
      to: "job-list",
    },
    {
      name: "Documents & Certifications",
      to: "documents-certifications",
    },
    {
      name: "CRM",
      to: `https://cloud.fexdrive.com/api/login?t=${cookies.get('fextoken')}&redirect=https://collaboration.fexdrive.com/crm`,
      type: 'link'
    },
    {
      name: "Prospective Customers",
      to: "prospective-customers",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            { item?.type !== undefined? 
              <a className={`nav-link ga-nav-tab`} href={item.to}>{ item.name }</a> : 
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            }
          </li>
        ))}
      </ul>
    </div>
  );
}
