import MagnifyIcon from "mdi-react/MagnifyIcon";
import EarthIcon from "mdi-react/EarthIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import AccountSwitchIcon from "mdi-react/AccountSwitchIcon";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Card, Button, Nav, Dropdown } from "react-bootstrap";
import {
  NavLink,
  Outlet,
  Modal,
  Link,
  useInRouterContext,
} from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import NewRequisitionFormBuilder from "./NewRequisitionFormBuilder";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {
  DeleteIcon,
  DropdownEyeIcon,
  EditIcon,
  LinkVariantIcon,
  RecieptIcon,
} from "../Icons";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";
import EditRequisitionFormBuilder from "./EditRequisitionFormBuilder";
import NewFillableFormModal from "./NewFillableFormModal";
import CachedIcon from "mdi-react/CachedIcon";
import { copyText, slugify } from "../../utils/helpers";
import FilledForms from "./FilledForms";
import { services } from "../../config";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import ApprovalBadge from "../utils/ApprovalBadge";
import FormPreviewModal from "./FormPreviewModal";

export default function Forms({ formType = "Public" }) {
  const initialFilterParams = {
    q: "",
    formType,
  };

  const queryClient = useQueryClient();
  const { backendUrl, user: authUser } = useAuth();
  const [selectedForm, setSelectedForm] = useState();
  const [showFormPreviewModal, setShowFormPreviewModal] = useState();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showEditRequisitionModal, setShowEditRequisitionModal] = useState(
    false
  );
  const [showFilledFormsModal, setShowFilledFormsModal] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    formType,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchForms = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/forms?&${queryString.stringify({
        ...queryParams,
        formType,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      forms: [],
    },
    refetch,
    isFetched,
  } = useQuery(
    ["SYSTEM_FORMS", formType, queryParams],
    () => fetchForms(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const enableForm = ({ id, enabled }) => {
    enableMutation.mutate(
      {
        id,
        enabled,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            ["SYSTEM_FORMS", formType, queryParams],
            (data) => {
              data.forms.map((el) => {
                if (el.id === id) {
                  el.enabled = enabled;
                }
                return el;
              });
              return data;
            }
          );
        },
      }
    );
  };

  const deleteFormApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteFormApi(payload), {
    onSuccess: () => {
      toast.success(`Form Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const enableFormApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/enable-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const enableMutation = useMutation((payload) => enableFormApi(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const deleteForm = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete this form",
      })
    ) {
      deleteMutation.mutate({
        id: el.id,
      });
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
      <div className="requisition-control-area d-flex align-items-center">
        <div className="search-area">
          <MagnifyIcon />
          <Form.Control
            id="queryParams-q"
            className=""
            name="q"
            value={filterParams.q}
            onChange={(e) => handleFilterParamsChange(e)}
            placeholder="Search Title..."
          />
        </div>

        {/*  <Form.Select
          name="Type"
          value={filterParams.Type}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Type</option>
          {data.formTypes.map((el) => (
            <option>{el.type}</option>
          ))}
        </Form.Select> */}

        <Form.Switch
          inline
          id="Draft"
          label="Draft"
          name="Draft"
          type={"checkbox"}
          checked={filterParams.Draft}
          onChange={(e) => handleFilterParamsChange(e)}
        />

        {/*   <Form.Select
          name="status"
          value={queryParams.status}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Status</option>
          <option>Pending</option>
          <option>Approved & Sent</option>
          <option>Approved & Closed</option>
          <option>Disapprove</option>
        </Form.Select> */}

        <Button
          onClick={() => refetch()}
          variant="outline-primary"
          className="border-0"
          title="Refresh"
        >
          <CachedIcon />
        </Button>
      </div>
      <main className="cash-book">
        <div className="content">
          <div className="dashboard-tabs innerpage-tabs px-4">
            <ul className="nav nav-tabs">
              <Nav>
                <NavLink
                  to="/requisition/forms/templates"
                  className={`nav-link ga-nav-tab`}
                >
                  Templates
                </NavLink>
                <NavLink
                  to="/requisition/forms/company"
                  className={`nav-link ga-nav-tab`}
                >
                  Company
                </NavLink>
                <NavLink
                  to="/requisition/forms/public"
                  className={`nav-link ga-nav-tab`}
                >
                  Public
                </NavLink>
                {/* <NavLink
                to="/requisition/forms/draft"
                className={`nav-link ga-nav-tab`}
              >
                Draft
              </NavLink> */}
              </Nav>
            </ul>
          </div>
          <header>
            <h1>Forms</h1>

            <button
              onClick={() => setShowNewRequisitionModal(true)}
              className="btn btn-primary new"
            >
              + New Form
            </button>
          </header>

          <div className="p-4">
            <div className="row gx-4 px-0">
              {data.forms.map((el, i) => (
                <div key={i} className="col-md-4 mb-4">
                  <Card>
                    <Card.Header className="bg-white border-0 p-4 pb-0 d-flex justify-content-between align-items-center">
                      <span className="text-light">
                        Created :{" "}
                        {el?.Date_Log
                          ? format(new Date(el.Date_Log), "dd/MM/yyyy")
                          : "..."}
                      </span>

                      <div className="d-flex gap-2 align-items-center">
                        {" "}
                        {el.enabled && (
                          <ApprovalBadge
                            text={"Live"}
                            className="approval"
                            bg="success"
                          />
                        )}
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border border-white"
                            bsPrefix="print more"
                          >
                            <DotsHorizontalIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowFormPreviewModal(true);
                              }}
                            >
                              <DropdownEyeIcon /> Preview
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                copyText(
                                  formType === "Public"
                                    ? `${services.frontendUrl}/public-form/${
                                        el.id
                                      }/${authUser.company}/${slugify(
                                        el.Title
                                      )}`
                                    : `${services.frontendUrl}/company-form/${
                                        el.id
                                      }/${authUser.company}/${slugify(
                                        el.Title
                                      )}`
                                )
                              }
                            >
                              <LinkVariantIcon />
                              Copy Link
                            </Dropdown.Item>
                            {el.approvalType === "Not-Required" &&
                            el.enabled ? (
                              <Dropdown.Item as="div">
                                <Link
                                  to={
                                    formType === "Public"
                                      ? `/public-form/${el.id}/${
                                          authUser.company
                                        }/${slugify(el.Title)}`
                                      : `/company-form/${el.id}/${
                                          authUser.company
                                        }/${slugify(el.Title)}`
                                  }
                                >
                                  <RecieptIcon /> Fill Form
                                </Link>
                              </Dropdown.Item>
                            ) : null}

                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowFilledFormsModal(true);
                              }}
                            >
                              <DropdownEyeIcon /> Filled Forms
                            </Dropdown.Item>

                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowEditRequisitionModal(true);
                              }}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              as="button"
                              className="text-danger"
                              onClick={() => deleteForm(el)}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>

                            <div className="p-3">
                              <Form.Switch
                                label="Enabled "
                                inline
                                type={"checkbox"}
                                checked={el.enabled}
                                onChange={(e) => {
                                  enableForm({
                                    id: el.id,
                                    enabled: e.target.checked,
                                  });
                                }}
                              />
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-4">
                      {" "}
                      <Card.Subtitle className="mb-2 text-muted fw-normal">
                        {el.formNumberPrefix || "..."} {el.Category || "..."}
                      </Card.Subtitle>
                      <Card.Title className="fw-bolder">
                        {el.Title || "..."}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              ))}
              {isFetched && isEmpty(data.forms) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>
            {/*   <div className="d-flex justify-content-between px-3 align-items-center pagination">
             
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleFilterParamsChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                hrefBuilder={hrefBuilder}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={handlePageChange}
                forcePage={queryParams.page - 1}
              />
            </div> */}
          </div>
        </div>
      </main>

      {showNewRequisitionModal && (
        <NewRequisitionFormBuilder
          showNewRequisitionModal={showNewRequisitionModal}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
          refetch={refetch}
          type={"Form"}
        />
      )}

      {selectedForm && showEditRequisitionModal ? (
        <EditRequisitionFormBuilder
          showEditRequisitionModal={showEditRequisitionModal}
          setShowEditRequisitionModal={setShowEditRequisitionModal}
          refetch={refetch}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
          type={"Form"}
        />
      ) : null}

      {selectedForm && showFilledFormsModal ? (
        <FilledForms
          form={selectedForm}
          setSelectedForm={setSelectedForm}
          setShowFilledFormsModal={setShowFilledFormsModal}
        />
      ) : null}

      {showFormPreviewModal && selectedForm ? (
        <FormPreviewModal
          showFormPreviewModal={showFormPreviewModal}
          setShowFormPreviewModal={setShowFormPreviewModal}
          template={selectedForm.Template}
          selectedFilledForm={selectedForm}
        />
      ) : null}

      <ModalLoader
        show={deleteMutation.isLoading || enableMutation.isLoading}
      />
    </>
  );
}
