import currency from "currency.js";
import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { qtyFormat, qtyFormatToString } from "../../utils/helpers";

export default function ConvertQuantity({ quantity = 0, desc = "" }) {
  const { isIronRod } = useAuth();
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);

  /*  useEffect(() => {
    console.log(generalSettings?.convertToTonsAndPcs);
  }, [generalSettings]); */

  return (
    <>
      {isIronRod && generalSettings?.convertToTonsAndPcs
        ? qtyFormatToString(qtyFormat(quantity, desc, itemMeasurements))
        : currency(quantity, {
            symbol: "",
            precision: 1,
          })
            .format()
            .toString()
            .replace(".0", "")}
    </>
  );
}
