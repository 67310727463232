import currency from "currency.js";
import { format } from "date-fns";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { customerFullName, paginationOptions } from "../../utils/helpers";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import Select from "react-select";
import AddAccounts from "../modals/AddAccounts";
import eventBus from "../../utils/EventBus";

export default function ExpensesSelector({
  handleSelectedExpense,
  usage = "",
  Type = "",
  onHide,
  type = "",
  DetailType = "",
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 50,
    Description: "",
    usage,
    Type,
    DetailType,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getExpenses = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    /*  data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    })); */
    return data;
  };

  const { data = { journal: [] } } = useQuery(
    ["GET_ACCOUNT_EXPENSES", debouncedqueryParams],
    () => getExpenses(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      {/*  <h2 className="h5 p-3 pb-1 m-0">Sales</h2> */}
      <div className="d-flex gap-1 align-items-center pe-3">
        <div className="search-area m-3 w-100">
          <MagnifyIcon />
          <input
            className="form-control search-input"
            name="Description"
            value={queryParams.Description}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                page: 1,
                [e.target.name]: e.target.value,
              })
            }
            placeholder="Search..."
            autoFocus
            autoComplete="off"
            type={"text"}
          />
        </div>
        {onHide && (
          <Button
            onClick={() => {
              eventBus.dispatch("ON_SHOW_CREATE_ACCOUNT_MODAL", {
                isOpen: true,
                type,
              });
              if (onHide) onHide();
            }}
            size="sm"
            className="text-nowrap"
          >
            + New
          </Button>
        )}
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">Account ID</th>
              <th scope="col">Description</th>
              <th scope="col">Account Type</th>
            </tr>
          </thead>
          <tbody>
            {data.journal &&
              data.journal.map((el, index) => (
                <tr
                  className="p-cursor"
                  key={index}
                  onClick={() =>
                    handleSelectedExpense && handleSelectedExpense(el)
                  }
                >
                  <td>{el.AccountID}</td>
                  <td> {el.Description} </td>
                  <td>{el.Type}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
