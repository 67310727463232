import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { useAuth } from "../../hooks/useAuth";
import currency from "currency.js";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { customerFullName, formatDate } from "../../utils/helpers";
import { uniqBy } from "lodash";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { BookIcon } from "../Icons";
import CachedIcon from "mdi-react/CachedIcon";

export default function JobItemsModal({ selectedJob, setSelectedJob }) {
  const { backendUrl } = useAuth();

  const fetchSetUpData = async () => {
    let response = await fetch(
      `${backendUrl}/api/job/job-items/${encodeURIComponent(
        selectedJob.jobNumber
      )}?fromSales=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    //  clean up

    if (data.jobItems) {
      const jobItemsByTransaction = uniqBy(data.jobItems, "TransactionID");
      data.jobItemsByTransaction = jobItemsByTransaction.map((el) => ({
        ...el,
        items: data.jobItems.filter(
          (item) => item.TransactionID === el.TransactionID
        ),
      }));
    }

    return data;
  };

  const {
    data = { jobItems: [], jobItemsByTransaction: [] },
    isFetching,
    isSuccess,
    refetch,
  } = useQuery(["__JOB_ITEMS"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setSelectedJob(null);
        }}
        dialogClassName="requisition-details-modal approve-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">
              Job Items{" "}
              <Button
                onClick={() => refetch()}
                className="text-primary"
                variant=""
              >
                <CachedIcon />{" "}
              </Button>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column pt-0">
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
            <h2>
              {selectedJob?.title} - {customerFullName(selectedJob.customer)}
            </h2>
          </div>

          <div className="content px-1 pb-4 pe-2 flex-grow-1">
            {data.jobItemsByTransaction.map((transaction, index) => (
              <div className="mb-5">
                <div className="d-flex  gap-3 mb-3  align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      //  className="bg-white border-0"
                      bsPrefix="print more"
                    >
                      <DotsVerticalIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="dropdown-with-icons"
                    >
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/invoice/${
                            transaction.TransactionID
                          }`}
                          target="blank"
                        >
                          <BookIcon />
                          Open Invoice - A4
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>{" "}
                  </Dropdown>
                  <h2>
                    {transaction.TransactionID} -{" "}
                    {transaction?.salesJobIncomeAccount.Ref} -{" "}
                    {transaction?.Date_Log}
                  </h2>
                </div>

                <Table borderless responsive striped className="product-table">
                  <thead className="sticky border-bottom">
                    <tr>
                      <th />
                      <th>Serivce Name</th>
                      <th title="Price Sold">Cost</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                      <th>Date</th>
                    </tr>
                  </thead>

                  <tbody className="blue-hover">
                    {transaction?.items &&
                      transaction.items.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{el.Item_Name}</td>
                          <td>
                            {currency(el.PriceSold, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.QTY, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.SubTotal, {
                              symbol: "",
                            }).format()}
                          </td>{" "}
                          <td>{el?.Date_Log}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            ))}

            {!isFetching && isSuccess && isEmpty(data?.jobItems) ? (
              <div>
                <NoTableItem queryParams={{}} />
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
