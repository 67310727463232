import { isEmpty, map, uniqBy } from "lodash";
import { Fragment, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import {
  convertMomentDate,
  customerFullName,
  scrollToElement,
  waitFor,
} from "../utils/helpers";
import CustomerSelectModal from "./CustomerSelectModal";
import {
  AddCircleIcon,
  CashSelectIcon,
  ChequeSelectIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DeleteIcon,
  DirectTransferSelectIcon,
  MoneyDollarIcon,
  NoSelectedItemIcon,
} from "./Icons";
import PageHeader from "./PageHeader";
import Datetime from "react-datetime";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { useFormik } from "formik";
import moment from "moment";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import useDebounce, { useEffectOnce } from "../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import ModalLoader from "./utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "./SelectBankDialog";
import { queryActions } from "../utils/reactQueryActions";
import { last } from "lodash";
import * as yup from "yup";
import { first } from "lodash";
import ConfirmDialog from "./ConfirmDialogue";
import { appSettings } from "../config";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import NumberCustomInput from "./utils/NumberCustomInput";
import { useStoreState } from "easy-peasy";

const paymentMethod = [
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },
  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card",
    value: "Credit/Debit Card",
  },
  /*  {
    icon: <CreditMemoSelectIcon />,
    label: "Credit Memo",
    value: "Credit Memo",
  }, */
  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
];

function duePaymentLabel(el) {
  return `${el.Trans_ID} -  ${el?.Payment_Due_Transaction?.ShipTo} - ${currency(
    el.AmountDue,
    {
      symbol: "",
    }
  ).format()} - ${el?.InvoiceCat || ""}`;
}

function CustomerRow({
  index,
  customer,
  editTable,
  remove,
  customers = [],
  addNewRow,
  backendUrl,
  getCustomerBalanceAndCredit,
  addToParentCustomerList,
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [duePaymentOptions, setDuePaymentOptions] = useState([]);
  const [invoiceCatOptions, setInvoiceCatOptions] = useState([]);

  const initialValues = {
    Post_Date: moment(),
    newInstallment: "",
    Remark: "",
    PaymentType: "Cash",
    duePaymentTransactionID: "",
    chequeNumber: "",
    BankName: "",
    Cust_ID: "",
    InvoiceCat: "None",
    //------------------------
    numberOfDrumsReturned: 0,
    costPerDrum: generalSettings?.costPerDrum,
    totalAmount: 0,
    usage: "drums",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: yup.object().shape({}),
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Cust_ID) => {
    const customer = customers.find((el) => el.Cust_ID === Cust_ID);

    if (Cust_ID && isEmpty(customer)) {
      return getCustomerBalanceAndCredit(Cust_ID);
    }

    // GET CUSTOMER BALANCE & CREDIT
    const duePayments = customer?.duePayments ? customer.duePayments : [];
    const invoiceCats = customer?.invoiceCats ? customer.invoiceCats : [];
    if (
      isEmpty(customer?.balance) ||
      isEmpty(duePayments) ||
      isEmpty(invoiceCats)
    ) {
      getCustomerBalanceAndCredit(Cust_ID);
    }

    setDuePaymentOptions([
      {
        label: "None",
        value: "",
      },
      ...duePayments.map((el) => ({
        ...el,
        label: duePaymentLabel(el),
        value: el.Trans_ID,
      })),
    ]);

    setInvoiceCatOptions([
      {
        label: "None",
        value: "None",
      },
      ...invoiceCats.map((el) => ({
        label: el.InvoiceCat,
        value: el.InvoiceCat,
      })),
    ]);

    formik.setFieldValue("newInstallment", duePayments[0]?.AmountDue);
    formik.setFieldValue("Cust_ID", Cust_ID);
  };

  useEffect(() => {
    if (customer?.duePayments) {
      setDuePaymentOptions([
        {
          label: "None",
          value: "",
        },
        ...customer?.duePayments.map((el) => ({
          ...el,
          label: duePaymentLabel(el),
          value: el.Trans_ID,
        })),
      ]);
    }
  }, [customer?.duePayments]);

  useEffect(() => {
    if (customer?.invoiceCats) {
      setInvoiceCatOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer?.invoiceCats.map((el) => ({
          label: el.InvoiceCat,
          value: el.InvoiceCat,
        })),
      ]);
    }
  }, [customer?.invoiceCats]);

  useEffectOnce(() => {
    if (customer?.Cust_ID) setUp(customer.Cust_ID);
  });

  useEffect(() => {
    formik.setFieldValue(
      "totalAmount",
      currency(formik.values.costPerDrum).multiply(
        formik.values.numberOfDrumsReturned
      ).value
    );
  }, [formik.values.costPerDrum, formik.values.numberOfDrumsReturned]);

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }

    editTable({
      index,
      formValues: {
        ...(formValues?.Cust_ID
          ? customers.find((el) => el.Cust_ID === formValues.Cust_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
  }, [formik.values.PaymentType]);

  const handleCustomerChange = (value) => {
    formik.setFieldValue("Cust_ID", value);
    if (value) {
      console.log(value);
      setUp(value);
      addNewRow(index);
    }
  };

  const fetchCustomers = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/customers?page=1&Limit=50&LastName=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.customers)
      callback(
        (data.customers = data.customers.map((el) => ({
          ...el,
          label: customerFullName(el),
          value: el.Cust_ID,
        })))
      );
  };

  const debouncedFetchUsers = _.debounce(fetchCustomers, 500);

  const loadOptions = (inputValue, callback) => {
    if (_.isEmpty(inputValue)) {
      return callback(null, { options: [] });
    }
    debouncedFetchUsers(inputValue, callback);
  };

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVerticalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              Remove Customer
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>
      {/*   <td>{customer.Cust_ID}</td> */}
      <td>
        {/* {customer?.Cust_ID} */}
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={({ value }) => formik.setFieldValue("PaymentType", value)}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        />
      </td>
      <td>
        <AsyncSelect
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          data={customers}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={customers.find((el) => el.value === formik.values.Cust_ID)}
          defaultOptions={customers}
          onChange={(selection) => {
            handleCustomerChange(selection?.value ? selection?.value : "");
            addToParentCustomerList(selection);
          }}
          id={`newInstallment-customer-${index}`}
          loadOptions={loadOptions}
        />{" "}
      </td>

      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      <td>
        <CurrencyCustomInput
          id={`costPerDrum-${customer.Cust_ID}`}
          name="costPerDrum"
          placeholder="0.00"
          defaultValue=""
          value={formik.values.costPerDrum}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <NumberCustomInput
          id={`numberOfDrumsReturned-${customer.Cust_ID}`}
          name="numberOfDrumsReturned"
          placeholder="0"
          value={formik.values.numberOfDrumsReturned}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>

      <td>
        <CurrencyCustomInput
          id={`totalAmount-${customer.Cust_ID}`}
          name="totalAmount"
          placeholder="0.00"
          value={formik.values.totalAmount}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          readOnly
        />
      </td>

      <td>
        <CurrencyCustomInput
          id={`newInstallment-${customer.Cust_ID}`}
          name="newInstallment"
          placeholder="0.00"
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>

      {/*   <td>
        <Select
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          menuPlacement="bottom"
          menuPosition="fixed"
          options={duePaymentOptions}
          key={duePaymentOptions}
          isSearchable={false}
          onChange={(selected) => {
            if (selected?.value) {
              formik.setFieldValue("duePaymentTransactionID", selected.value);
              formik.setFieldValue("newInstallment", selected.AmountDue);

              if (customer.Cust_ID === "000101") {
                formik.setFieldValue(
                  "Remark",
                  selected?.Payment_Due_Transaction?.ShipTo || ""
                );
              }
            }
          }}
        />
      </td> */}

      <td>
        {currency(customer.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.Post_Date && !!formik.errors.Post_Date
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>
      <td>{customer.TransactionID}</td>
      <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={false}
          options={invoiceCatOptions}
          key={invoiceCatOptions}
          onChange={({ value }) => formik.setFieldValue("InvoiceCat", value)}
        />
      </td>
    </tr>
  );
}

export default function PostDrumPayment() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );

  const [tableData, setTableData] = useState([
    {
      Cust_ID: "",
      TransactionID: `STN${Date.now()}1`,
    },
  ]);
  const setSelectedCustomer = (customer) => {
    const lastitem = last(tableData);
    customer.TransactionID = `STN${Date.now()}0`;

    if (lastitem.Cust_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}2` },
      ]);
    } else {
      setTableData([
        ...tableData,
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    // console.log(formValues.newInstallment);
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateCustomersLedger = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/customers/post-payments`, {
      method: "POST",
      headers: {
        /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateCustomersLedgerMutation = useMutation(
    (payload) => updateCustomersLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([{ Cust_ID: "", TransactionID: `STN${Date.now()}1` }]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    if (
      !(await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      }))
    ) {
      return;
    }
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyCustomerIndex = table.findIndex((el) => el.Cust_ID === "");
    if (foundEmptyCustomerIndex !== -1) {
      //  console.log(foundEmptyCustomerIndex);
      scrollToElement(`#newInstallment-customer-${foundEmptyCustomerIndex}`);
      return toast.error(`Select a Customer`);
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.totalAmount) <= 0
    );
    if (foundEmptyInstallment) {
      scrollToElement(`#totalAmount-${foundEmptyInstallment.Cust_ID}`);
      return toast.error(`Invalid totalAmount added`);
    }

    // Ensure only valid data are sent
    /* console.log(tableData);
    return; */
    const customersData = tableData.filter(
      (el) => el.Cust_ID && el.totalAmount
    );

    updateCustomersLedgerMutation.mutate(
      {
        customers: customersData,
      },
      {
        onSuccess: () => {
          /* if (customersData && customersData.length === 1) {
            const customer = first(customersData);
            window.open(
              `${backendUrl}/api/invoice/pdf/receipt/${
                customer.TransactionID
              }?thermalPrinter=${appSettings.requireSalesRep}`,
              "_blank",
              "noopener,noreferrer"
            );
          } */
        },
      }
    );
  };

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/customers?page=1&limit=50`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    }));
    return data;
  };

  const { data = { customers: [] }, isFetching } = useQuery(
    [queryActions.CUSTOMERS],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const addToParentCustomerList = (newCustomerFromSearch) => {
    queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
      if (
        !data.customers.find(
          (el) => el.Cust_ID === newCustomerFromSearch.Cust_ID
        )
      ) {
        data.customers = [...data.customers, newCustomerFromSearch];
        return data;
      } else {
        return data;
      }
    });
  };

  const getCustomerBalanceAndCredit = async (Cust_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      //await waitFor(4000);
      let response = await fetch(
        `${backendUrl}/api/customers/balance-and-credit/${Cust_ID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
          data.customers = data.customers.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  return (
    <main className="create-invoice">
      <div className="p-3 content">
        <div className="d-flex content-holder rounded">
          <section className="item-details customers">
            <div>
              <header>
                <h1>Update Drum Account </h1>
              </header>

              <div className="selected-data-area mt-3">
                {/*  */}
                <div className="table-holder">
                  {!isEmpty(tableData) ? (
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table text-nowrap post-payment"
                    >
                      <thead>
                        <tr>
                          <th />
                          {/* <th>Customer ID</th> */}
                          <th>Payment Type</th>
                          <th>Customer Name</th>
                          <th>Remark</th>

                          <th>Cost Per Drums </th>
                          <th>Number of Drums Returned </th>
                          <th>Total Amount </th>

                          <th>Amount Paid for Drums </th>
                          {/*   <th>Link Payment to Invoice</th> */}
                          <th>Balance on Ledger</th>

                          <th>Transaction Date</th>
                          <th>Transaction ID</th>
                          <th>Invoice Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((el, index) => (
                          <Fragment key={el.TransactionID}>
                            <CustomerRow
                              customers={data?.customers}
                              index={index}
                              customer={el}
                              editTable={editTable}
                              remove={remove}
                              addNewRow={addNewRow}
                              backendUrl={backendUrl}
                              getCustomerBalanceAndCredit={
                                getCustomerBalanceAndCredit
                              }
                              addToParentCustomerList={addToParentCustomerList}
                            />
                          </Fragment>
                        ))}
                      </tbody>
                    </Table>
                  ) : null}
                </div>

                <button
                  onClick={() => setShowCustomerSelectorModal(true)}
                  type="button"
                  className="btn text-primary my-3 d-flex align-items-center gap-2"
                >
                  <AddCircleIcon />
                  Add Customer
                </button>

                {/*  No item  */}
                {isEmpty(tableData) ? (
                  <div className="no-item my-5">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected a Customer yet</h2>
                      <p>
                        You can click +Add Customer Button to add a Customer to
                        the table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {!isEmpty(tableData) && (
              <div className="d-flex justify-content-between total-info">
                <div />
                <div>
                  <Button
                    onClick={() => post()}
                    disabled={updateCustomersLedgerMutation.isLoading}
                    type="button"
                    className="py-3 px-5"
                  >
                    Post Payments
                  </Button>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      {/*   Modals */}
      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          withCredit={true}
          withPaymentDue={true}
          isMulti={false}
          alreadySelectedCustomers={tableData}
          withInvoiceCat={true}
        />
      )}

      <ModalLoader
        show={updateCustomersLedgerMutation.isLoading || isFetching}
      />

      <ModalLoader
        show={isLoading}
        title="Please wait, getting customer balance and due payments..."
      />
    </main>
  );
}
