import { Modal, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/document-certification.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon, TrashIcon } from "./Icons";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { services } from "../config";
import { toast } from "react-toastify";
import LoginDialog from "./LoginDialog";
import { useState } from "react";
import { didYouKnowOptions, nanoid } from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import { useEffectOnce } from "../utils/hooks";

export default function EditDocumentCertificationModal({
  showEditDocumentCertificationModal,
  setShowEditDocumentCertificationModal,
  selectedDocument,
  setSelectedDocument,
  refetch,
}) {
  const files = [{name: "file1"}]
  const { backendUrl } = useAuth();
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [file, setFile] = useState(files)

  const formik = useFormik({
    initialValues: {
      name: '',
      docType: '',
      expiryDate: '',
      file: '',
      comment: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Please name of document / certification"),
      docType: yup.string().required("What is the document type"),
      expiryDate: yup.string().required("Please enter document expiry date"),
    //   file: yup.string().required("Please select a file to upload")
    }),
    onSubmit: (values) => {
        console.log(values)
      const { expiryDate } = values
      submit({
        ...values,
        expiryDate: expiryDate.format()
      });
    },
  });

  useEffectOnce(() => {
    formik.setValues({
      name: selectedDocument.Name,
      docType: selectedDocument.DocType,
      expiryDate: moment(selectedDocument.ExpiryDate).format("DD/MM/YYYY"),
      comment: selectedDocument.Comment,
    });
  });

  const addFiles = () =>{
    console.log(file)
    setFile((file) => {
      return [...file, {name: `file${file.length +1}`}]
    })
    console.log(file)
  }

  const removeFile = (index) => {
    
  }

  const updateDocumentCertification = async (payload) => {
    let data = new FormData();
    data.append("name", payload.name)
    data.append("docType", payload.docType)
    data.append("comment", payload.comment)
    data.append("expiryDate", payload.expiryDate)
    data.append("file", payload.file)
    
    let response = await fetch(`${backendUrl}/api/document/update`, {
      method: "POST",
      credentials: "include",
      body: data,
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "multipart/form-data",
      // },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateDocumentCertificationMutation = useMutation(
    (payload) => updateDocumentCertification(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        console.log(data)

        if (setSelectedDocument) setSelectedDocument(data.document);
        formik.resetForm();
        if (refetch) refetch();
        setShowEditDocumentCertificationModal(false);
      },
      onError: (err) => {
        console.log(err.message)
        if(err.message === 'User not logged in') {
          toast.error(`${ err.message }`);
        } else {
          toast.error(`Unable to perform action`); 
        }
      },
    }
  );

  const submit = (payload) => {
    updateDocumentCertificationMutation.mutate(payload);
  };

  const authenticateAdmin = async () => {
    if (await LoginDialog()) {
      setIsAdminAuthenticated(true);
    }
  };

  return (
    <Modal
      show={showEditDocumentCertificationModal}
      onHide={() => setShowEditDocumentCertificationModal(false)}
      dialogClassName="document-certification"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Edit Document</h1>
          <p>Edit a document by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row pb-2"
          autoComplete="off"
        >
          <div className="col-md-12">
            <Form.Group className="mb-3">
              <Form.Label className="mb-2">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name of Document"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2 pb-2">
              <Form.Label className="mb-1">Type of Document</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type of Document"
                name="docType"
                value={formik.values.docType}
                isInvalid={formik.touched.docType && !!formik.errors.docType}
                onChange={formik.handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.docType}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2 pb-2">
              <Form.Label className="mb-1" htmlFor="DOB">
                Expiry Date
              </Form.Label>
              <Datetime
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="expiryDate"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.expiryDate && !!formik.errors.expiryDate
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select expiry date",
                  readOnly: true,
                }}
                value={formik.values.expiryDate}
                onChange={(date) => {

                  formik.setFieldValue("expiryDate", date, true);
                }}
                onBlur={() => formik.setFieldTouched("expiryDate", true)}
              />
              {formik.touched.expiryDate && !!formik.errors.expiryDate ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.expiryDate}
                </span>
              ) : null}
            </Form.Group>

            {/* {
              file.map((f, index) => {
                return (<Form.Group className="mb-2 pb-2 filebox" key={index}>
                  <Form.Label className="mb-1">Select File</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Select file"
                    name={f.name}
                    value=""
                    onChange={formik.handleChange}
                    isInvalid={formik.touched[f.name] && !!formik.errors[f.name]}
                  />
                  {index > 0 && <span className={'removeFile'}>
                    <TrashIcon color={'#c33'} onClick={removeFile(index)} />
                  </span>}
                  <Form.Control.Feedback type="invalid">
                    {formik.errors[f.name]}
                  </Form.Control.Feedback>
                </Form.Group>)
              })
            } */}

                <Form.Group className="mb-2 pb-2 filebox">
                  <Form.Label className="mb-1">Select File</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Select file"
                    name={'file'}
                    value={file.name}
                    onChange={(event) => {
                      formik.setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    isInvalid={formik.touched.file && !!formik.errors.file}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.file}
                  </Form.Control.Feedback>
                </Form.Group>

            {/* <Form.Group className="mb-2 pb-2">
              <Button 
              variant="primary"
              type="button"
              className="w-25 submit-btn"
              onClick={addFiles}>Add more file</Button>
            </Form.Group> */}

            <Form.Group className="">
              <Form.Label className="mb-1">Comment(Optional)</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter comment"
                name="comment"
                rows={5}
                value={formik.values.comment}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.comment &&
                  !!formik.errors.comment
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comment}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="w-100 submit-btn mt-3"
              disabled={updateDocumentCertificationMutation.isLoading}
            >
              {updateDocumentCertificationMutation.isLoading
                ? "Please wait..."
                : "Save Changes"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
