import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {
  Button,
  Form,
  Table,
  Dropdown,
  Overlay,
  Popover,
} from "react-bootstrap";
import {
  DeleteIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
  DropdownMessageIcon,
  DropdownSendIcon,
  FilterTwoIcon,
  LinkVariantIcon,
} from "../Icons";
import "./../../assets/scss/requisition/index.scss";
import ApprovalBadge from "./../../components/utils/ApprovalBadge";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { services } from "../../config";
import queryString from "query-string";
import { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import lodash, { flatMap, isEmpty } from "lodash";
import { format } from "date-fns";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ApproveAndCloseDialog, RejectDialog } from "./Dialog";
import { requisitionActions } from "../../utils/reactQueryActions";
import Avatar from "../utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import {
  paginationOptions,
  resolveApprovalBadgeBg,
  searchParamsToObject,
} from "../../utils/helpers";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import useDebounce, {
  ScrollOnLocationChange,
  ScrollOnParamsChange,
} from "../../utils/hooks";
import eventBus from "./../../utils/EventBus";
import { lowerCase } from "lodash";
import { last } from "lodash";
import NewRequisitionModal from "./NewRequisition";
import NewFundAndInventoryModal from "./NewFundAndInventoryModal";
import ReactPaginate from "react-paginate";
import ConfirmDialog from "../ConfirmDialogue";
import NoTableItem from "../utils/NoTableItem";

export default function Request() {
  const { user: authUser, backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);

  let url = new URL(document.location.href);
  url = searchParamsToObject(url.searchParams.entries());
  const requiredQueryParams = {
    page: 1,
    limit: 40,
    q: "",
    status: "",
    type: "",
  };
  const initialQueryParams = !isEmpty(url)
    ? { ...requiredQueryParams, ...url }
    : requiredQueryParams;

  const [searchQuery, setSearchQuery] = useSearchParams(initialQueryParams);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const debouncedQueryParams = useDebounce(queryParams, 500);
  useEffect(() => {
    setSearchQuery(debouncedQueryParams);
  }, [debouncedQueryParams]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  ScrollOnParamsChange(debouncedQueryParams);

  const getRequisition = async (debouncedQueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/requisition?&${queryString.stringify(
        debouncedQueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    error,
    data = { count: 0, requisitions: [] },
    refetch,
    isFetching,
  } = useQuery(
    [requisitionActions.GET_REQUISITION, debouncedQueryParams],
    () => getRequisition(debouncedQueryParams),
    {
      keepPreviousData: true,
      staleTime: 0,
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const reject = async (requisition, mainRequisition) => {
    await RejectDialog({
      authUser,
      requisition,
      requisitionTitle: mainRequisition.title,
      previousRequisitionTrackId: requisition.ID,
    });
  };

  const approveAndClose = async (requisition, mainRequisition) => {
    await ApproveAndCloseDialog({
      authUser,
      requisition,
      requisitionTitle: mainRequisition.title,
      previousRequisitionTrackId: requisition.ID,
    });
  };

  const handleRowClick = (e) => {};

  function onNewRequisitionTrack(updatedRequisition) {
    if (updatedRequisition) {
      queryClient.setQueryData(
        [requisitionActions.GET_REQUISITION, debouncedQueryParams],
        (data) => {
          const requisitionIndex = data.requisitions.findIndex(
            (el) => el.requestid === updatedRequisition.requestid
          );
          data.requisitions[requisitionIndex] = updatedRequisition;
          return data;
        }
      );

      queryClient.setQueryData(
        [
          requisitionActions.GET_REQUISITION_DETAILS,
          updatedRequisition.requestid,
        ],
        (data) => {
          data.requisition = updatedRequisition;
          return data;
        }
      );
    }
  }

  const onNewRequisitionCreated = (requisition) => {
    if (
      requisition.type === debouncedQueryParams.type ||
      !debouncedQueryParams.type
    ) {
      queryClient.setQueryData(
        [requisitionActions.GET_REQUISITION, debouncedQueryParams],
        (data) => {
          data.requisitions = [requisition, ...data.requisitions];
          return data;
        }
      );
    }
    copyLink(requisition);
  };

  eventBus.useCustomEventListener("REQUISITION_UPDATED", onNewRequisitionTrack);
  eventBus.useCustomEventListener(
    "REQUISITION_CREATED",
    onNewRequisitionCreated
  );

  const canPerformAction = (requisition) => {
    const pendingRequisition = requisition;
    return (
      requisition &&
      pendingRequisition.status === "Pending" &&
      pendingRequisition.receivedby === authUser.Staff_ID
    );
  };
  const handlePageChange = ({ selected }) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setQueryParams({
      ...queryParams,
      page: selected + 1,
    });
  };

  const hrefBuilder = (pageIndex) => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  };

  const copyLink = (requisition) => {
    const textarea = document.createElement("textarea");
    textarea.textContent = `${
      services.frontendUrl
    }/requisition/request/preview/${requisition.requestid}`;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Link Copied");
  };

  const deleteRequisition = async (payload) => {
    let response = await fetch(`${backendUrl}/api/requisition/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteRequisition(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const deleteReq = async (requisition) => {
    if (
      await ConfirmDialog({
        title: "Delete Requisition",
        description: "Are you sure, you want to delete this requisition?",
      })
    ) {
      deleteMutation.mutate(requisition);
    }
  };

  return (
    <>
      <div className="requisition-control-area d-flex">
        <div className="search-area">
          <MagnifyIcon />
          <Form.Control
            id="queryParams-q"
            className=""
            name="q"
            value={queryParams.q}
            onChange={(e) => handleSearchQueryChange(e)}
            placeholder="Search Title..."
          />
        </div>
        <Form.Select
          name="type"
          value={queryParams.type}
          onChange={(e) => handleSearchQueryChange(e)}
        >
          <option value="">Sort by Type</option>
          <option>Material Request</option>
          <option>Fund Request</option>
          {/*<option>Normal</option>*/}

          <option value={"Inventory"}>Sales Inventory Request</option>
          <option>Pending Service Invoice</option>
          <option>Other</option>
        </Form.Select>

        <Form.Select
          name="status"
          value={queryParams.status}
          onChange={(e) => handleSearchQueryChange(e)}
        >
          <option value="">Sort by Status</option>
          <option>Pending</option>
          <option>Approved & Sent</option>
          <option>Approved & Closed</option>
          <option>Disapprove</option>
        </Form.Select>

        <Button
          onClick={() => refetch()}
          variant="outline-primary"
          className="border-0"
        >
          <CachedIcon />
        </Button>
      </div>

      <main className="cash-book">
        <div className="content">
          <header>
            <h1>
              <label htmlFor="queryParams-q" className="btn filter pt-2">
                <FilterTwoIcon />
              </label>
              Requisition
            </h1>

            <button
              onClick={() => setShowNewRequisitionModal(true)}
              className="btn btn-primary new"
            >
              + New Requisition
            </button>
          </header>

          <div className="px-4 pb-4 ">
            <Table borderless responsive striped className="product-table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Requisition</th>
                  <th>Type</th>
                  <th>Request From</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Approve with</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.requisitions
                  ? data.requisitions.map((el, index) => {
                      const latestRequisition = last(el.requisitiontracks);
                      return (
                        <tr
                          key={index}
                          onClick={(e) => {
                            handleRowClick(e);
                          }}
                        >
                          <td>
                            <span className="text-light">
                              {data.startIndex + index + 1}
                            </span>
                          </td>
                          <td>
                            <span className="fw-6">{el.title}</span>
                          </td>
                          <td>{el.type}</td>
                          <td>{latestRequisition.sentbyUser?.Name || "..."}</td>
                          <td>
                            {format(
                              new Date(latestRequisition.regdate),
                              "dd/MM/yyyy"
                            )}
                          </td>
                          <td
                            className="p-cursor"
                            onClick={() =>
                              navigate(
                                `/requisition/request/details/${el.requestid}`
                              )
                            }
                          >
                            <ApprovalBadge
                              text={latestRequisition.status}
                              className="approval"
                              bg={resolveApprovalBadgeBg(
                                latestRequisition.status
                              )}
                            />
                          </td>
                          <td>
                            <div className="d-flex gap-2 align-items-center">
                              <div className="avatar-list d-flex">
                                {flatMap(
                                  el.requisitiontracks.map((el) => [
                                    el.receivedbyUser,
                                    el.sentbyUser,
                                  ])
                                )
                                  .filter(
                                    (el) =>
                                      el && authUser.Staff_ID !== el.Staff_ID
                                  )
                                  .slice(0, 2)
                                  .map((el, index, arr) => (
                                    <Avatar
                                      key={index}
                                      name={el?.Name ? el.Name : ""}
                                      style={{
                                        zIndex: arr.length - 1,
                                        fontSize: "0.75rem",
                                      }}
                                      userId={el?.Staff_ID}
                                    />
                                  ))}
                              </div>
                              {latestRequisition.receivedbyUser?.Name || "..."}
                            </div>
                          </td>
                          <td className="position-relative">
                            <div className="d-flex gap-2">
                              <Link
                                to={
                                  el.type === "Normal" &&
                                  isEmpty(el.filledFormData)
                                    ? `/requisition/request/details/${
                                        el.requestid
                                      }`
                                    : el.type === "Pending Service Invoice"
                                    ? `/sales-and-invoicing/edit-pending-service-invoice/${el.requestid.replace(
                                        "REQ",
                                        ""
                                      )}`
                                    : `/requisition/request/preview/${
                                        el.requestid
                                      }`
                                }
                                className="btn border bg-white text-primary d-flex align-items-center text-nowrap"
                              >
                                View Details
                              </Link>

                              <Dropdown style={{ margin: 0 }}>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-light-blue text-primary"
                                  bsPrefix="print more"
                                >
                                  <DotsHorizontalIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => copyLink(el)}
                                  >
                                    <LinkVariantIcon />
                                    Copy Link
                                  </Dropdown.Item>
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={
                                        el.type === "Pending Service Invoice"
                                          ? `/sales-and-invoicing/edit-pending-service-invoice/${el.requestid.replace(
                                              "REQ",
                                              ""
                                            )}`
                                          : `/requisition/request/preview/${
                                              el.requestid
                                            }`
                                      }
                                    >
                                      <DropdownEyeIcon /> Preview Requisition
                                    </Link>
                                  </Dropdown.Item>

                                  {/*   <Dropdown.Item as="div">
                                    <Link
                                      to={`/requisition/request/conversations/${
                                        el.requestid
                                      }`}
                                    >
                                      <DropdownMessageIcon /> Send Message
                                    </Link>
                                  </Dropdown.Item> */}
                                  {canPerformAction(latestRequisition) &&
                                  isEmpty(el.filledFormData) ? (
                                    <>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() =>
                                          approveAndClose(latestRequisition, el)
                                        }
                                      >
                                        <DropdownCheckIcon /> Appove & Close
                                      </Dropdown.Item>
                                      <Dropdown.Item as="div">
                                        <Link
                                          to={`/requisition/request/approve-and-send/${
                                            el.requestid
                                          }`}
                                        >
                                          <DropdownSendIcon /> Appove & Sent
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="text-danger"
                                        onClick={() =>
                                          reject(latestRequisition, el)
                                        }
                                      >
                                        <DropdownCloseIcon /> Reject Requisition
                                      </Dropdown.Item>
                                    </>
                                  ) : null}
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deleteReq(el)}
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>

            {isEmpty(data.requisitions) && !isFetching ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <NoTableItem queryParams={{}} />
              </div>
            ) : null}

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                hrefBuilder={hrefBuilder}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={handlePageChange}
                forcePage={queryParams.page - 1}
              />
            </div>
          </div>

          {/* <div className="pagination ">
             
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select name="rows" className="form-select ">
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>

            
              <div className="pagintion_right">
                <Pagination>
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>

                  <Pagination.Ellipsis className="border-none" />

                  <Pagination.Item>{6}</Pagination.Item>
                  <Pagination.Next className="next" />
                </Pagination>
              </div>
            </div>*/}
        </div>
      </main>

      {showNewRequisitionModal && (
        <NewRequisitionModal
          setShowNewRequisitionModal={setShowNewRequisitionModal}
        />
      )}

      <Outlet />
    </>
  );
}
