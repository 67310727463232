import currency from "currency.js";
import { format } from "date-fns";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { customerFullName, paginationOptions } from "../../utils/helpers";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";

export default function TransactionsTable({ handleSelectedTransaction }) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    TransactionID: "",
    Cust_ID: "",
    withCustomer: false,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  // This is getting from sales
  const getTransactions = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/transaction?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { transaction: [] } } = useQuery(
    [queryActions.GET_TRANSACTIONS, debouncedqueryParams],
    () => getTransactions(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const fetchCustomers = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/customers?page=1&Limit=50&LastName=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.customers)
      callback(
        (data.customers = data.customers.map((el) => ({
          ...el,
          label: customerFullName(el),
          value: el.Cust_ID,
        })))
      );
  };

  const debouncedFetchUsers = _.debounce(fetchCustomers, 500);

  const loadOptions = (inputValue, callback) => {
    /*  if (_.isEmpty(inputValue)) {
      return callback(null, { options: [] });
    } */
    debouncedFetchUsers(inputValue, callback);
  };

  return (
    <div className="items-table-area rounded">
      <h2 className="h5 p-3 pb-1 m-0">Sales</h2>
      <div className="d-flex gap-2 align-items-center pe-3">
        <div className="search-area m-3 w-75">
          <MagnifyIcon />
          <input
            className="form-control search-input"
            name="TransactionID"
            value={queryParams.TransactionID}
            onChange={(e) =>
              setQueryParams({
                ...queryParams,
                page: 1,
                [e.target.name]: e.target.value,
              })
            }
            placeholder="Transaction ID"
            autoFocus
            autoComplete="off"
            type={"text"}
          />
        </div>

        <Datetime
          dateFormat="MMM DD, YYYY"
          timeFormat={false}
          closeOnSelect={true}
          closeOnClickOutside={true}
          inputProps={{
            className: `date-input form-control`,
            placeholder: "Select date",
            readOnly: true,
          }}
          value={queryParams.date}
          onChange={(date) => {
            setQueryParams({
              ...queryParams,
              date,
            });
          }}
        />
      </div>
      <div className="p-3 pt-1 border-bottom mb-3">
        {/*  <Select
          classNamePrefix={"form-select"}
          placeholder="Search Customer"
          options={data?.customers}
          value={data?.customers.find((el) => el.value === queryParams.Cust_ID)}
          onChange={(selection) => {
            setQueryParams({
              ...queryParams,
              page: 1,
              Cust_ID: selection?.value,
            });
          }}
          isClearable
        />{ */}
        <div>
          <AsyncSelect
            classNamePrefix={"form-select"}
            placeholder="Search Customer"
            isSearchable={true}
            isClearable={true}
            defaultOptions
            onChange={(selection) => {
              setQueryParams({
                ...queryParams,
                page: 1,
                Cust_ID: selection?.value,
              });
            }}
            loadOptions={loadOptions}
          />
        </div>
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">Transaction ID</th>
              <th scope="col">Customer</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {data.transactions &&
              data.transactions.map((el, index) => (
                <tr
                  className="p-cursor"
                  key={index}
                  onClick={() =>
                    handleSelectedTransaction && handleSelectedTransaction(el)
                  }
                >
                  <td>{el.TransactionID}</td>
                  <td> {customerFullName(el.customer)} </td>
                  <td>
                    {el?.Date_Log
                      ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
