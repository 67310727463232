import MagnifyIcon from "mdi-react/MagnifyIcon";
import EarthIcon from "mdi-react/EarthIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import AccountSwitchIcon from "mdi-react/AccountSwitchIcon";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Card, Button, Nav, Dropdown } from "react-bootstrap";
import { NavLink, Outlet, Modal } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import NewRequisitionFormBuilder from "./NewRequisitionFormBuilder";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { DeleteIcon, DropdownEyeIcon, EditIcon } from "../Icons";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";
import EditRequisitionFormBuilder from "./EditRequisitionFormBuilder";
import NewFillableFormModal from "./NewFillableFormModal";
import CachedIcon from "mdi-react/CachedIcon";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import ApprovalBadge from "../utils/ApprovalBadge";
import FormPreviewModal from "./FormPreviewModal";

export default function Send({ formType }) {
  const initialFilterParams = {
    q: "",
    formType,
  };

  const queryClient = useQueryClient();

  const { backendUrl } = useAuth();
  const [template, setTemplate] = useState();
  const [showFormPreviewModal, setShowFormPreviewModal] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showEditRequisitionModal, setShowEditRequisitionModal] = useState(
    false
  );
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [
    showNewCustomRequisitionModal,
    setShowNewCustomRequisitionModal,
  ] = useState(false);
  const [showNewFillableFormModal, setShowNewFillableFormModal] = useState(
    false
  );
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    formType,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchTemplates = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/forms/templates?&${queryString.stringify({
        ...queryParams,
        formType,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      templates: [],
    },
    refetch,
    isFetched,
  } = useQuery(
    ["FORM_TEMPLATES", formType, queryParams],
    () => fetchTemplates(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const enableForm = ({ id, enabled }) => {
    enableMutation.mutate(
      {
        id,
        enabled,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            ["FORM_TEMPLATES", formType, queryParams],
            (data) => {
              data.templates.map((el) => {
                if (el.id === id) {
                  el.enabled = enabled;
                }
                return el;
              });
              return data;
            }
          );
        },
      }
    );
  };

  const deleteTemplateApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteTemplateApi(payload), {
    onSuccess: () => {
      toast.success(`Template Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const enableFormApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/enable`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const enableMutation = useMutation((payload) => enableFormApi(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const deleteTemplate = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete this template",
      })
    ) {
      deleteMutation.mutate({
        id: el.id,
      });
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
      <div className="requisition-control-area d-flex align-items-center">
        <div className="search-area">
          <MagnifyIcon />
          <Form.Control
            id="queryParams-q"
            className=""
            name="q"
            value={filterParams.q}
            onChange={(e) => handleFilterParamsChange(e)}
            placeholder="Search Title..."
          />
        </div>

        {/*  <Form.Select
          name="Type"
          value={filterParams.Type}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Type</option>
          {data.formTypes.map((el) => (
            <option>{el.type}</option>
          ))}
        </Form.Select> */}

        <Form.Select
          name="Type"
          value={filterParams.Type}
          onChange={(e) => handleFilterParamsChange(e)}
        >
          <option value="">Sort By</option>
          <option value="Organisation">Organisation</option>
          <option value="Public">Public</option>
        </Form.Select>

        <Form.Switch
          inline
          id="Draft"
          label="Draft"
          name="Draft"
          type={"checkbox"}
          checked={filterParams.Draft}
          onChange={(e) => handleFilterParamsChange(e)}
        />

        {/*   <Form.Select
          name="status"
          value={queryParams.status}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Status</option>
          <option>Pending</option>
          <option>Approved & Sent</option>
          <option>Approved & Closed</option>
          <option>Disapprove</option>
        </Form.Select> */}

        <Button
          onClick={() => refetch()}
          variant="outline-primary"
          className="border-0"
          title="Refresh"
        >
          <CachedIcon />
        </Button>
      </div>

      <main className="cash-book">
        <div className="content">
          <div className="dashboard-tabs innerpage-tabs px-4">
            <ul className="nav nav-tabs">
              <Nav>
                <NavLink
                  to="/requisition/forms/templates"
                  className={`nav-link ga-nav-tab`}
                >
                  Templates
                </NavLink>
                <NavLink
                  to="/requisition/forms/company"
                  className={`nav-link ga-nav-tab`}
                >
                  Company
                </NavLink>
                <NavLink
                  to="/requisition/forms/public"
                  className={`nav-link ga-nav-tab`}
                >
                  Public
                </NavLink>
                {/*  <NavLink
                to="/requisition/forms/draft"
                className={`nav-link ga-nav-tab`}
              >
                Draft
              </NavLink> */}
              </Nav>
            </ul>
          </div>
          <header>
            <h1> </h1>

            <button
              onClick={() => setShowNewRequisitionModal(true)}
              className="btn btn-primary new"
            >
              + New Form Template
            </button>
          </header>

          <div className="p-4">
            <div className="row gx-4 px-0">
              {data.templates.map((el, i) => (
                <div key={i} className="col-md-4 mb-4">
                  <Card>
                    <Card.Header className="bg-white border-0 p-4 pb-0 d-flex justify-content-between align-items-center">
                      <span className="text-light">
                        Created :{" "}
                        {el?.Date_Log
                          ? format(new Date(el.Date_Log), "dd/MM/yyyy")
                          : "..."}
                      </span>

                      <div className="d-flex gap-2 align-items-center">
                        {" "}
                        {el.enabled && (
                          <ApprovalBadge
                            text={"Live"}
                            className="approval"
                            bg="success"
                          />
                        )}
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border border-white"
                            bsPrefix="print more"
                          >
                            <DotsHorizontalIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowFormPreviewModal(true);
                              }}
                            >
                              <DropdownEyeIcon /> Preview
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowEditRequisitionModal(true);
                              }}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              className="text-danger"
                              onClick={() => deleteTemplate(el)}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>

                            <div className="p-3">
                              <Form.Switch
                                label="Enabled "
                                inline
                                type={"checkbox"}
                                checked={el.enabled}
                                onChange={(e) => {
                                  enableForm({
                                    id: el.id,
                                    enabled: e.target.checked,
                                  });
                                }}
                              />
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-4">
                      {" "}
                      <Card.Subtitle className="mb-2 text-muted fw-normal">
                        {el.Type || "..."}
                      </Card.Subtitle>
                      <Card.Title className="fw-bolder">
                        {el.Title || "..."}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer className="bg-white border-top-0 p-4">
                      {/*  <Dropdown className="col px-0">
                        <Dropdown.Toggle
                          variant="outline-primary w-100 border-light py-2"
                          disabled={formType === "draft"}
                        >
                          Use Form Template
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-with-icons">
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setTemplate(el.Template);
                              setShowNewCustomRequisitionModal(true);
                            }}
                          >
                            <AccountSwitchIcon />
                            New Requisition
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => {
                              setTemplate(el.Template);
                              setShowNewFillableFormModal(true);
                            }}
                          >
                            <AccountGroupIcon />
                            Form
                          </Dropdown.Item>
                        
                        </Dropdown.Menu>
                      </Dropdown> */}

                      <Button
                        variant="outline-primary w-100 border-light py-2"
                        onClick={() => {
                          setSelectedForm(el);
                          setShowCreateFormModal(true);
                        }}
                      >
                        Use Form Template
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
              ))}

              {isFetched && isEmpty(data.templates) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>
            {/*   <div className="d-flex justify-content-between px-3 align-items-center pagination">
             
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) =>  handleFilterParamsChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
             <option value="50">50 rows</option><option value="100">100 rows</option>   </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                hrefBuilder={hrefBuilder}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={handlePageChange}
                forcePage={queryParams.page - 1}
              />
            </div> */}
          </div>
        </div>
      </main>

      {showNewRequisitionModal && (
        <NewRequisitionFormBuilder
          showNewRequisitionModal={showNewRequisitionModal}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
          refetch={refetch}
          type={"Template"}
        />
      )}

      {selectedForm && showCreateFormModal ? (
        <NewRequisitionFormBuilder
          showNewRequisitionModal={showCreateFormModal}
          setShowNewRequisitionModal={setShowCreateFormModal}
          refetch={() => {}}
          type={"Form"}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
        />
      ) : null}

      {selectedForm && showEditRequisitionModal ? (
        <EditRequisitionFormBuilder
          showEditRequisitionModal={showEditRequisitionModal}
          setShowEditRequisitionModal={setShowEditRequisitionModal}
          refetch={refetch}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
          type={"Template"}
        />
      ) : null}

      {showNewCustomRequisitionModal && template ? (
        <NewCustomRequisitionModal
          template={template}
          setShowNewCustomRequisitionModal={setShowNewCustomRequisitionModal}
          setTemplate={setTemplate}
        />
      ) : null}

      {showNewFillableFormModal && template ? (
        <NewFillableFormModal
          template={template}
          setShowNewFillableFormModal={setShowNewFillableFormModal}
          setTemplate={setTemplate}
        />
      ) : null}

      {showFormPreviewModal && selectedForm ? (
        <FormPreviewModal
          showFormPreviewModal={showFormPreviewModal}
          setShowFormPreviewModal={setShowFormPreviewModal}
          template={selectedForm.Template}
          selectedFilledForm={selectedForm}
        />
      ) : null}

      <ModalLoader show={deleteMutation.isLoading} />
    </>
  );
}
