import { Modal, Form } from "react-bootstrap";
import "./../assets/scss/new-entity-modal.scss";
import { useQuery } from "react-query";
import { useState } from "react";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../utils/helpers";

import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import ApprovalBadge from "./utils/ApprovalBadge";
import VendorRFQChildFormsModal from "./VendorRFQChildForms";

export default function VendorRFQFormsModal({
  showForms,
  setShowForms,
  selectedVendors,
  name,
  showSend,
  getselectedForm,
  purchaseOrder,
  showReuisition,
  okay,
}) {
  const { backendUrl } = useAuth();
  const [childForms, setChildForm] = useState([]);
  const [showchildForm, setShowChildForm] = useState(false);
  const [mainFormID, setMainFormID] = useState("");
  const [childCount, setChildCount] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [motherForm, setMotherForm] = useState("");
  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    count: 0,
  });

  const { data } = useQuery(
    ["GET all the forms", queryParams, searchData],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/forms?&limit=${queryParams.limit}&page=${
          queryParams.page
        }&q=${searchData}`,
        "GET"
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  // console.log(data);
  const formChildren = async (id) => {
    const children = await fetchActionsUtil(
      `${backendUrl}/api/forms/filled-forms?FormID=${id}`,
      "GET"
    );
    setChildForm(children.data.forms);
    setChildCount(children.data.count);
    setShowChildForm(true);
  };

  return (
    <div>
      <Modal
        show={showForms}
        onHide={() => setShowForms(false)}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="mx-4">
            <h5>Select Forms</h5>
          </Modal.Title>
          <div>
            <Form.Control
              id="queryParams-q"
              className=""
              name="q"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search by name"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="filter-body row">
              {data?.data &&
                data?.data?.forms?.map((el) => (
                  <div className="col-md-3 mb-4">
                    <div
                      className="card p-cursor"
                      onClick={() => {
                        formChildren(el.id);
                        setMainFormID(el.id);
                        setMotherForm(el);
                      }}
                      key={el.id}
                    >
                      <div className="bg-white border-0 p-4 pb-0 d-flex justify-content-between align-items-center card-header">
                        <span className="text-light">
                          {" "}
                          Created:{" "}
                          {el?.Date_Log
                            ? format(new Date(el.Date_Log), "dd/MM/yyyy")
                            : "..."}
                        </span>
                        {/* <div className="d-flex gap-2 align-items-center">
                          {el.enabled && (
                            <ApprovalBadge
                              text={"Live"}
                              className="approval"
                              bg="success"
                            />
                          )}
                        </div> */}

                        {okay && <Form.Check onChange={() => okay(el)} />}
                      </div>

                      <div className="card-body p-3">
                        <div className="card-subtitle mb-2 text-muted fw-normal">
                          {el.Category || "..."}
                        </div>
                        <div className="fw-bolder card-title h5">
                          {" "}
                          {el.Title || "..."}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Modal.Body>

        {data?.data?.count > 0 && (
          <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
            <div className="pagination_left d-flex gap-3 align-items-center">
              <p className="m-0 p-0">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select "
                onChange={(e) =>
                  setQueryParams({ ...queryParams, limit: e.target.value })
                }
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="40">40 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(queryParams.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        )}
      </Modal>
      {showchildForm && (
        <VendorRFQChildFormsModal
          showchildForm={showchildForm}
          setShowChildForm={setShowChildForm}
          childForms={childForms}
          selectedVendors={selectedVendors}
          setShowForms={setShowForms}
          name={name}
          mainFormID={mainFormID}
          showSend={showSend}
          getselectedForm={getselectedForm}
          purchaseOrder={purchaseOrder}
          childCount={childCount}
          motherForm={motherForm}
          showReuisition={showReuisition}
          okay={okay}
        />
      )}
    </div>
  );
}
