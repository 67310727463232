import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import { Button, Table, Form } from "react-bootstrap";
import { useIsCashier } from "../../utils/hooks";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../utils/ModalLoader";
import { DropdownCloseIcon } from "../Icons";
import { last, isEmpty } from "lodash";

const paymentTypesForFundRequest = [
  {
    label: "Advanced",
    value: "Advance",
  },
  {
    label: "Direct",
    value: "Direct",
  },
];

function Attachments({ files = [], setFiles }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>

      <div>
        {files?.map((el, index) => (
          <div key={index}>
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/requisition/files/${
                  el.systemFileName
                }/${el.name}`}
                target="_blank"
                rel="noreferrer"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function FundRequestForm({
  requisition,
  tableData,
  total,
  refetch,
  fundRequest,
  currencySymbol,
}) {
  const isCashier = useIsCashier();
  const { backendUrl, token, user: authUser } = useAuth();
  const navigate = useNavigate();

  const postRequisitionData = async (payload) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          //  console.log(Boolean(!file?.fileId));
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el.fileId))
    );
    let response = await fetch(
      `${backendUrl}/api/requisition/update-data/${requisition.requestid}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          /*   Accept: "Application/json",
          "Content-Type": "Application/json", */
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const postMutation = useMutation((payload) => postRequisitionData(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const postExpense = () => {
    navigate("/expenses-entry", {
      state: {
        expenses: tableData.map((el) => ({
          ...el,
          Particulers: el.description,
          Amount: currency(el?.unitCost, {
            symbol: "",
            separator: "",
          })
            .multiply(el?.quantity)
            .format(),
        })),
        job: requisition.job,
        Vendor_ID: requisition.vendor
          ? requisition.vendor.split(" / ").pop()
          : "",
        requisitionApprovedBy: last(requisition?.requisitiontracks)
          .receivedbyUser.Name,
        requisitionTitle: requisition?.title,
        currency: requisition.currency,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      initialAdvancePayment: 0,
      paymentType: "",
      amountAdvanced: 0,
      amountExpensed: 0,
      files: [],
      //  fundRequestRemark: "",
    },
    validationSchema: yup.object().shape({
      paymentType: yup.string().required("required"),
      initialAdvancePayment: yup.number().required("required"),
    }),
    onSubmit: (values) => {
      if (
        values.paymentType !== "Direct" &&
        requisition?.initialAdvancePayment &&
        Number(values.amountExpensed) !==
          Number(requisition?.initialExpenseAmount) &&
        Number(total.value) !== Number(values.amountExpensed)
      ) {
        formik.setFieldError(
          "amountExpensed",
          //"Amount Advanced and initial Expense do not match.\n please adjust items to match  Amount Advanced"
          "Amount Expensed does not match items in requisition. \n Please adjust items to match"
        );
        //  toast.error("...");
      } else {
        values.amountAdvanced = values.initialAdvancePayment;

        //-------

        let description = [
          ...tableData,
          ...(fundRequest?.description ? [fundRequest] : []),
        ]
          .map((el) => {
            delete el.subtotal;
            return Object.values(el).join(",,,");
          })
          .join("&&&");

        description = description + "&&&";

        //-------
        postMutation.mutate(
          {
            ...values,
            reimburseToCompany,
            reimburseToEmployee,
            description,
            //  amount: total,
          },
          {
            onSuccess: () => {
              if (
                values.paymentType === "Direct" &&
                requisition?.status === "Approved & Closed"
              ) {
                postExpense();
              } else {
                refetch();
              }
            },
          }
        );
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      initialAdvancePayment: requisition.initialAdvancePayment || 0,
      paymentType: requisition.paymentType,
      amountAdvanced: requisition.amountAdvanced || 0,
      amountExpensed: requisition.amountExpensed || 0,
      files: requisition?.files ? JSON.parse(requisition?.files) : [],
    });
  }, [
    requisition?.initialAdvancePayment,
    requisition.paymentType,
    requisition.amountAdvanced,
    requisition.amountExpensed,
    requisition.files,
    // formik,
  ]);

  const reimburseToCompany = useMemo(() => {
    return Number(formik.values.amountAdvanced) >
      Number(formik.values.amountExpensed)
      ? currency(formik.values.amountAdvanced).subtract(
          formik.values.amountExpensed
        ).value
      : 0;
  }, [formik.values.amountAdvanced, formik.values.amountExpensed]);

  const reimburseToEmployee = useMemo(() => {
    return Number(formik.values.amountExpensed) >
      Number(formik.values.amountAdvanced)
      ? currency(formik.values.amountExpensed).subtract(
          formik.values.amountAdvanced
        ).value
      : 0;
  }, [formik.values.amountAdvanced, formik.values.amountExpensed]);

  return (
    <>
      <Form
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="p-3 my-4 border"
      >
        <Table borderless className="upsert-table">
          {/* <thead>
    <tr>
      <th colSpan={2}>Retirement:</th>
    </tr>
  </thead> */}
          <tbody>
            <tr>
              <td className="fw-bold  text-right">Initial Total Expense</td>
              <td>
                <CurrencyCustomInput
                  currencySymbol={currencySymbol}
                  value={requisition?.initialExpenseAmount}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold  text-right">Payment Type</td>
              <td>
                <Select
                  classNamePrefix={`form-select`}
                  options={paymentTypesForFundRequest}
                  value={paymentTypesForFundRequest.find(
                    (el) => el.value === formik.values.paymentType
                  )}
                  onChange={(selected) =>
                    formik.setFieldValue("paymentType", selected?.value)
                  }
                  isDisabled={!isCashier}
                />
                {formik.touched.paymentType && formik.errors.paymentType ? (
                  <span className="text-danger mt-2">
                    {formik.errors.paymentType}
                  </span>
                ) : null}
              </td>
            </tr>
            {formik.values.paymentType === "Advance" && (
              <tr>
                <td className="fw-bold  text-right">
                  {requisition?.initialAdvancePayment &&
                  requisition?.paymentType === "Advance"
                    ? "Initial Advanced Amount "
                    : `Advanced Amount `}{" "}
                </td>
                <td>
                  <CurrencyCustomInput
                    currencySymbol={currencySymbol}
                    readOnly={!isCashier}
                    name="initialAdvancePayment"
                    placeholder="0.00"
                    value={formik.values.initialAdvancePayment}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                      /*  if (requisition?.paymentType === "Advance") {
                        formik.setFieldValue("amountAdvanced", value);
                      } */
                    }}
                  />
                  {formik.touched.initialAdvancePayment &&
                  !!formik.errors.initialAdvancePayment ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.initialAdvancePayment}
                    </span>
                  ) : null}
                </td>
              </tr>
            )}
            {requisition?.paymentType === "Advance" ? (
              <>
                <tr>
                  <td colSpan={2}>
                    {" "}
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h2>Retirement</h2>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Amount Advanced</td>
                  <td>
                    <CurrencyCustomInput
                      //readOnly={authUser.Staff_ID !== requisition?.requestby}\
                      currencySymbol={currencySymbol}
                      readOnly={true}
                      name="amountAdvanced"
                      placeholder="0.00"
                      value={formik.values.amountAdvanced}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Amount Expensed</td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly={authUser.Staff_ID !== requisition?.requestby}
                      name="amountExpensed"
                      placeholder="0.00"
                      value={formik.values.amountExpensed}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                    />
                    {formik.touched.amountExpensed &&
                    !!formik.errors.amountExpensed ? (
                      <span
                        className="custom-invalid-feedback  d-block mt-1"
                        style={{ width: "16rem" }}
                      >
                        {formik.errors.amountExpensed}
                      </span>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">
                    Amount Reimbursable to Company
                  </td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly
                      value={reimburseToCompany}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">
                    Amount Reimbursable to Employee
                  </td>
                  <td>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      readOnly
                      value={reimburseToEmployee}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold  text-right">Attachments</td>
                  <td>
                    <Attachments
                      files={formik.values.files}
                      setFiles={(files) => formik.setFieldValue("files", files)}
                    />
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </Table>

        <div>
          {isEmpty(requisition?.paymentType) && isCashier ? (
            <Button variant="primary" className="" type="submit">
              Authorize
            </Button>
          ) : null}

          <>
            {requisition.paymentType === "Direct" && (
              <>
                {requisition?.status === "Approved & Closed" && isCashier ? (
                  <Button variant="primary" className="" type="submit">
                    Post Expense
                  </Button>
                ) : null}

                {requisition?.status !== "Approved & Closed" && isCashier ? (
                  <Button variant="primary" className="" type="submit">
                    Authorize
                  </Button>
                ) : null}
              </>
            )}
          </>

          {/*   */}
          {requisition.paymentType === "Advance" && (
            <>
              {requisition?.initialAdvancePayment &&
              authUser.Staff_ID === requisition?.requestby &&
              requisition?.status !== "Approved & Closed" ? (
                <Button
                  variant="primary"
                  className=""
                  type="submit"
                  title={authUser.Staff_ID}
                >
                  Save
                </Button>
              ) : null}

              {/*requisition?.amountExpensed &&*/
              isCashier && requisition?.status === "Approved & Closed" ? (
                <Button
                  variant="primary"
                  className=""
                  type="button"
                  onClick={() => postExpense()}
                >
                  Post Expense
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Form>
      <ModalLoader show={postMutation.isLoading} />
    </>
  );
}
