import { format } from "date-fns";
import { Modal, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import RequisitionService from "../../hooks/requisitionService";
import { isEmpty, uniqBy } from "lodash";
import ModalLoader from "../utils/ModalLoader";
import { useEffectOnce } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { LoaderIcon } from "../Icons";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import { useStoreState } from "easy-peasy";

export default function NewCustomRequisitionModal({
  setShowNewCustomRequisitionModal,
  template,
  sendAfterRequisition,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const { createRequisitionMutation } = RequisitionService();
  const iframeRef = useRef();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [reciever, setReceiver] = useState();

  const formik = useFormik({
    initialValues: {
      title: "",
      cost: 0.0,
      description: "",
      department: "",
      jobGrade: "",
      recipient: "",
      comment: " ",
      vendor: "nil",
      type: "Normal",
      filledFormData: "",
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      description: yup.string().required(),
      // filledFormData: yup.string().required(),
    }),
    onSubmit: (values) => {
      values.amount = 0;
      values.requestto = values.recipient;
      values.regdate = format(new Date(), "yyyy-MM-dd");
      values.status = "Pending";

      if (isEmpty(values.comment)) values.comment = "...";

      /*  console.log(values);
      return; */

      createRequisitionMutation.mutate(
        { ...values },
        {
          onSuccess: (data) => {
            // formik.resetForm();
            sendAfterRequisition && sendAfterRequisition(data.requisition);
            eventBus.dispatch("REQUISITION_CREATED", data.requisition);
            setShowNewCustomRequisitionModal(false);
          },
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  useEffectOnce(() => {
    try {
      const page = JSON.parse(template)[0];
      const signatories = page.children
        .filter((el) => el.type === "signatoryElement")
        .map((el) => el.props.fields);

      if (signatories) {
        const permissions = signatories.flat().map((el) => el.permission);

        if (permissions) {
          setReceiver(permissions[0]);

          formik.setFieldValue("department", permissions[0].department);
          formik.setFieldValue("recipient", permissions[0].staff);
          formik.setFieldValue("jobGrade", permissions[0].jobGrade);
        }
      }
    } catch (err) {
      console.log(err);
    }
  });

  const setformData = async (data) => {
    formik.setFieldValue("filledFormData", data);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
    /*   setTimeout(() => {
      formik.submitForm();
    }, 500); */
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      setShowNewCustomRequisitionModal(false);
    } else if (message.data.type === "SAVE") {
      //  console.log(message.data.value, "react");
      setformData(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: JSON.parse(template),
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  /* const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { users } = { users: null },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  ); */

  /*  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  }); */

  /*  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]); */

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values.department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    return {
      departments: data.departments,
      jobGrades: uniqBy(jobGrades, "jobGrade"),
      staffs: data.staffs.filter(
        (el) =>
          el.Department === formik.values.department &&
          el.jobGrade === formik.values.jobGrade
      ),
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowNewCustomRequisitionModal(false)}
        //   dialogClassName="requisition-details-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="lg"
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5"> New Requisition</h1>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="approve-action p-0">
          <Form noValidate onSubmit={formik.handleSubmit} className="p-3 px-4">
            <Form.Group className="mb-3">
              <Form.Label>Title :</Form.Label>
              <Form.Control
                name="title"
                placeholder="Enter a title"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description :</Form.Label>

              <Form.Control
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                as="textarea"
                placeholder="Enter your detail description"
                rows={3}
                isInvalid={
                  formik.touched.description && !!formik.errors.description
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row gap-3">
              <Form.Group className="col mb-3">
                <Form.Label>Department :</Form.Label>
                <Form.Select
                  name="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.department && !!formik.errors.department
                  }
                  disabled={reciever?.department}
                >
                  <option value="">Any Department</option>
                  {data?.departments &&
                    data.departments.map((el, index) => (
                      <option key={index} value={el.Department}>
                        {el.Department}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.department}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col mb-3">
                <Form.Label>Job Grade :</Form.Label>
                <Form.Select
                  name="jobGrade"
                  value={formik.values.jobGrade}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.jobGrade && !!formik.errors.jobGrade
                  }
                  disabled={reciever?.jobGrade}
                >
                  <option value="">Any Job Grade</option>
                  {staffSelectOptions?.jobGrades &&
                    staffSelectOptions.jobGrades.map((el, index) => (
                      <option key={index} value={el.jobGrade}>
                        {el.jobGrade}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.jobGrades}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col mb-3">
                <Form.Label>Recipient :</Form.Label>
                <Form.Select
                  name="recipient"
                  value={formik.values.recipient}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.recipient && !!formik.errors.recipient
                  }
                  disabled={isFetching || reciever?.staff}
                >
                  <option value="">Any Staff </option>
                  {staffSelectOptions?.staffs &&
                    staffSelectOptions?.staffs.map((el, index) => (
                      <Fragment key={index}>
                        {/*  {el.Staff_ID !== authUser.Staff_ID && (
                         
                        )} */}
                        <option value={el.Staff_ID}>{el.Name}</option>
                      </Fragment>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.recipient}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Form>

          <div className="form-area border">
            {!isFetching ? (
              <iframe
                ref={iframeRef}
                id="Frame"
                onLoad={(e) => {
                  setIsLoadingIframe(false);
                  sendSetupData();
                }}
                className="w-100"
                style={{ backgroundColor: "#E5E7EB", minHeight: "100vh" }}
                title="Form Builder"
                src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
              />
            ) : null}

            {isLoadingIframe && !isFetching ? (
              <div className="global-spinner">
                <LoaderIcon className="spin text-primary" />
              </div>
            ) : null}
          </div>

          {/*   <div className="row gap-3">
            <Form.Group className="col mb-3">
              <Form.Label>Department :</Form.Label>
              <Form.Select
                name="department"
                value={formik.values.department}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.department && !!formik.errors.department
                }
              >
                <option value="">Select Recipient's Department</option>
                {departments &&
                  departments.map((el, index) => (
                    <option key={index} value={el.Department}>
                      {el.Department}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.department}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col mb-3">
              <Form.Label>Recipient :</Form.Label>
              <Form.Select
                name="recipient"
                value={formik.values.recipient}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.recipient && !!formik.errors.recipient
                }
                disabled={isLoadingUsers}
              >
                <option value="">Select Recipient </option>
                {users &&
                  users.map((el, index) => (
                    <Fragment key={index}>
                      {el.Staff_ID !== authUser.Staff_ID && (
                        <option value={el.Staff_ID}>{el.Name}</option>
                      )}
                    </Fragment>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.recipient}
              </Form.Control.Feedback>
            </Form.Group>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={createRequisitionMutation.isLoading}
            onClick={() => setShowNewCustomRequisitionModal(false)}
            type="button"
            variant="white"
            className="border bg-white"
            size="lg"
          >
            Cancel
          </Button>
          <Button
            disabled={createRequisitionMutation.isLoading}
            type="button"
            variant="primary"
            className=""
            onClick={() => save()}
            size="lg"
          >
            {createRequisitionMutation.isLoading ? "Please wait..." : "Post"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <ModalLoader show={isFetching } /> */}
    </>
  );
}
