import React from "react";
import { useState } from "react";
import eventBus from "../../utils/EventBus";
import NewCustomerModal from "../NewCustomerModal";
import AddAccounts from "./AddAccounts";

export default function GlobalModals() {
  const [CREATE_ACCOUNT_MODAL, SET_CREATE_ACCOUNT_MODAL] = useState({
    isOpen: false,
    usage: "",
    type: "",
  });

  eventBus.useCustomEventListener(
    "ON_SHOW_CREATE_ACCOUNT_MODAL",
    ({ isOpen, usage, type }) => {
      SET_CREATE_ACCOUNT_MODAL({ isOpen, usage, type });
    }
  );

  const [CREATE_CUSTOMER_MODAL, SET_CREATE_CUSTOMER_MODAL] = useState({
    isOpen: false,
  });

  eventBus.useCustomEventListener(
    "ON_SHOW_CREATE_CUSTOMER_MODAL",
    ({ isOpen }) => {
      SET_CREATE_CUSTOMER_MODAL({ isOpen });
    }
  );

  return (
    <>
      {CREATE_ACCOUNT_MODAL.isOpen && (
        <AddAccounts
          show={CREATE_ACCOUNT_MODAL.isOpen}
          onHide={() =>
            SET_CREATE_ACCOUNT_MODAL({ isOpen: false, usage: "", type: "" })
          }
          refetch={() => {}}
          usage={CREATE_ACCOUNT_MODAL.usage}
          type={CREATE_ACCOUNT_MODAL.type}
          accountCreated={(account) =>
            eventBus.dispatch("JOURNAL_ACCOUNT_CREATED", account)
          }
        />
      )}

      {CREATE_CUSTOMER_MODAL.isOpen && (
        <NewCustomerModal
          showCreateNewCustomerModal={CREATE_CUSTOMER_MODAL.isOpen}
          setShowCreateNewCustomerModal={(value) => {
            SET_CREATE_CUSTOMER_MODAL({ isOpen: value });
          }}
          onHide={() => SET_CREATE_CUSTOMER_MODAL({ isOpen: false })}
          refetch={() => {}}
          setSelectedCustomer={(customer) =>
            eventBus.dispatch("CUSTOMER_CREATED", customer)
          }
        />
      )}
    </>
  );
}
