import CurrencyInput from "react-currency-input-field";
import "./../../assets/scss/scoped/currency-input.scoped.scss";

export default function CurrencyCustomInput({
  className,
  isInvalid,
  currencySymbol = "₦",
  ...props
}) {
  return (
    <div
      className={`currency-input ${
        isInvalid ? "border-danger shadow-none" : ""
      }`}
    >
      <span className="currency text-nowrap">{currencySymbol}</span>
      <CurrencyInput
        {...props}
        decimalScale={2}
        onClick={(e) => e.target.select()}
      />
    </div>
  );
}
