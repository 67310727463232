import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery, useMutation } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import queryString from "query-string";
// import { CSVLink } from "react-csv";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ExchangeFunds, GroupUsers } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { fetchActionsUtil } from "../../utils/helpers";
import CreateWarehouseModal from "../modals/CreateWarehouse";
import CreateWarehouseUserModal from "../modals/CreateWarehouseUsers";
import Avatar from "../utils/Avatar";
import EditWarehouseModal from "../modals/EditWarehouse";
import ConfirmDialog from "../ConfirmDialogue";

function WareHouse() {
  useScrollTop();
  const initialFilterParams = {
    startDate: "",
    endDate: "",
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateUsersModal, setOpenCreateUsersModal] = useState(false);
  const [singleWarehouse, setSingleWarehouse] = useState({
    warehouseName: "",
    warehouseUsers: [],
    warehouseId: "",
  });

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const navigate = useNavigate();

  const { data, refetch, isFetching } = useQuery(
    ["FETCH_WAREHOUSE", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse?${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const deletetheWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/delete-warehouse/${payload}`,
        "delete"
      ),
    {
      onSuccess: () => {
        toast.success("Warehouse saved Successfully");
        refetch();
      },
      onError: () => {
        toast.error("Unable to delete warehouse");
      },
    }
  );

  const deleteWarehouse = async (id) => {
    if (
      await ConfirmDialog({
        title: "Delete Warehouse",
        description: `Are you sure you want to delete this warehouse `,
      })
    ) {
      deletetheWarehouse.mutate(id);
    }
  };

  // console.log(data?.warehouseUsers);
  const onGoToWarehouse = (namewh, cater, whID) => {
    window.localStorage.setItem(
      "warehouseName",
      JSON.stringify({ namewh, cater, whID })
    );
    navigate("/warehouse-inventory/inventry-entry");
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book table-sticky-header">
      <div className="content">
        <header className="container">
          <h1>
            Warehouse
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5">
            {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

            <div className="position-relative">
              <RsDateRangePicker
                placement="bottomStart"
                value={
                  filterParams.startDate && filterParams.endDate
                    ? [
                        parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
            </div>

            <button
              onClick={() => setOpenCreateModal(true)}
              className="btn bg-primary text-white"
            >
              Create New <ExchangeFunds color="white" />
            </button>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
          </div>
        </header>

        <div>
          <Table
            // responsive
            borderless
            striped
            className="product-table container"
          >
            <thead className="position-sticky top-0">
              <tr>
                <th />
                <th>Warehouse Name</th>
                <th>Warehouse Type</th>
                <th>Warehouse Category</th>
                <th>Created Date</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              {data?.warehouses?.map((ek, i) => (
                <tr
                  key={i}
                  onDoubleClick={() =>
                    onGoToWarehouse(ek.W_name, ek.W_Category, ek.W_ID)
                  }
                  className="p-cursor"
                >
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-white border-0"
                        bsPrefix="print more"
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={() =>
                            onGoToWarehouse(ek.W_name, ek.W_Category, ek.W_ID)
                          }
                          className="p-cursor"
                        >
                          Open
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="div"
                          className="p-cursor"
                          onClick={() => {
                            setSingleWarehouse({
                              ...singleWarehouse,
                              warehouseName: ek.W_name,
                              warehouseId: ek.W_ID,
                            });
                            setOpenEditModal(true);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="div"
                          className="p-cursor"
                          onClick={() => {
                            deleteWarehouse(ek.W_ID);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td
                  // onClick={() => setOpenEditModal(true)}
                  >
                    {ek.W_name}
                  </td>
                  <td>{ek?.WH_ContentType?.name}</td>
                  <td>{ek.W_Category}</td>
                  <td>{ek.W_date}</td>
                  <td>
                    <div
                      className="text-white position-relative"
                      onClick={() => {
                        setSingleWarehouse({
                          warehouseName: ek.W_name,
                          warehouseUsers: data?.warehouseUsers[ek.W_name],
                          warehouseId: ek.W_ID,
                        });
                        setOpenCreateUsersModal(true);
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <div className="avatar-list d-flex">
                          {data?.warehouseUsers[ek.W_name].map(
                            (u, i, arr) =>
                              i < 3 && (
                                <Avatar
                                  key={i}
                                  name={u?.Name ? u.Name : ""}
                                  userId={u?.Staff_ID}
                                  style={{
                                    zIndex: arr.length - 1,
                                    fontSize: "0.75rem",
                                  }}
                                />
                              )
                          )}
                          {data?.warehouseUsers[ek.W_name]?.length > 4 ? (
                            <Avatar
                              extralText={`+${data?.warehouseUsers[ek.W_name]
                                ?.length - 3}`}
                              style={{
                                zIndex: 6,
                                fontSize: "1rem",
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {data?.warehouses?.length < 1 && (
          <NoTableItem queryParams={queryParams} />
        )}
        <div className="pagination">
          {/* Left */}
          {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

          {/* right */}
          {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
        </div>
      </div>
      <ModalLoader show={isFetching} />
      {openCreateModal && (
        <CreateWarehouseModal
          show={openCreateModal}
          onHide={() => setOpenCreateModal(false)}
          refetch={refetch}
        />
      )}
      {openCreateUsersModal && (
        <CreateWarehouseUserModal
          show={openCreateUsersModal}
          onHide={() => setOpenCreateUsersModal(false)}
          refetch={refetch}
          singleWarehouse={singleWarehouse}
        />
      )}
      {openEditModal && (
        <EditWarehouseModal
          show={openEditModal}
          onHide={() => setOpenEditModal(false)}
          refetch={refetch}
          singleWarehouse={singleWarehouse.warehouseName}
          warehouseId={singleWarehouse.warehouseId}
        />
      )}
    </main>
  );
}

export default WareHouse;
