import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useState } from "react";
import { Modal, Table, Dropdown } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { DeleteIcon, DropdownEyeIcon } from "../Icons";
import queryString from "query-string";
import { formatDate, formatNumberSystem } from "../../utils/helpers";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import FormPreviewModal from "./FormPreviewModal";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { parse, format } from "date-fns";

export default function FilledForms({
  form,
  setSelectedForm,
  setShowFilledFormsModal,
}) {
  const { backendUrl } = useAuth();
  const [selectedFilledForm, setSelectedFilledForm] = useState();

  const [showFormPreviewModal, setShowFormPreviewModal] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    FormID: form.id,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getForms = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/forms/filled-forms?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { forms: [] }, isFetched, refetch } = useQuery(
    ["FILLED_FORMS", debouncedqueryParams],
    () => getForms(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const preview = (form) => {
    setSelectedFilledForm(form);
    setShowFormPreviewModal(true);
  };

  const deleteApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete-filled-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteApi(payload), {
    onSuccess: () => {
      toast.success(` Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });
  const deleteForm = async (form) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete this form",
      })
    ) {
      deleteMutation.mutate({
        id: form.id,
      });
    }
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered={true}
        onHide={() => {
          setSelectedForm(null);
          setShowFilledFormsModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">Filled Forms for {form?.Title}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 align-items-center pe-3 mb-3">
            <div className="global-search-area w-75">
              <MagnifyIcon />
              <input
                className="form-control search-input"
                name="q"
                value={queryParams.q}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Search..."
                autoFocus
                autoComplete="off"
                type={"text"}
              />
            </div>

            <RsDateRangePicker
              placement="auto"
              value={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </div>

          {!isEmpty(data?.forms) && (
            <Table borderless striped responsive className="product-table">
              <thead>
                <tr>
                  <th />
                  <th>S/N</th>
                  <th>Form Number</th>
                  <th>Staff Name</th>
                  <th>Form Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.forms.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => preview(el)}
                          >
                            <DropdownEyeIcon /> View Form
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => deleteForm(el)}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>

                    <td>{index + 1}</td>
                    <td>
                      {formatNumberSystem(
                        el?.formNumberPrefix,
                        el?.currentNumber
                      )}
                    </td>
                    <td>{el?.UserName || "..."}</td>
                    <td>{el?.formName || "..."}</td>
                    <td className="text-nowrap">
                      {el?.Date_Log ? formatDate(el.Date_Log) : "..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {isFetched && isEmpty(data?.forms) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}
        </Modal.Body>
      </Modal>

      {showFormPreviewModal && selectedFilledForm ? (
        <FormPreviewModal
          showFormPreviewModal={showFormPreviewModal}
          setShowFormPreviewModal={setShowFormPreviewModal}
          template={selectedFilledForm.filledFormData}
          selectedFilledForm={selectedFilledForm}
          setSelectedFilledForm={setSelectedFilledForm}
        />
      ) : null}
    </>
  );
}
